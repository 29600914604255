import { orderBy } from 'lodash'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { getQueryString, getTotalItemPrice } from './SABSearchItem.utils'
import { SABSearchItemInfoProps } from './SABSearchItem.props'
import SABSearchItemOfferer from './SABSearchItemOffer'
import SABSearchItemActions from './SABSearchItemActions'
import SABSearchItemService from './SABSearchItemService'
import { TOP_MARGIN_2, LEFT_PADDING_2, TOP_PADDING } from './constants'

const SABSearchItemInfo: React.FC<SABSearchItemInfoProps> = (props) => {
  const { quote, searchQuery } = props
  const {
    price_details: priceDetails,
    estimated_prices: estimatedPrices,
    incomplete_prices: incompletePrices,
  } = quote
  const { freight_currency: currency } = priceDetails
  const createdBySupplier = quote.user_id !== props.currentUser.id
  const isBooking = (!estimatedPrices && !incompletePrices) || createdBySupplier
  const queryString = getQueryString({ ...searchQuery }, quote)
  const hasNoServices = quote.price_details.services.length === 0
  const services = orderBy(
    quote.price_details.services,
    (service) => (service.subtotal ? parseFloat(service.subtotal) : 0),
    'desc'
  )

  return (
    <Grid
      item
      lg={4}
      xl={4}
      xs={12}
      sm={12}
      md={6}
      mt={TOP_MARGIN_2}
      pl={LEFT_PADDING_2}
    >
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {quote.seller_organization &&
            !createdBySupplier &&
            quote.schedule.carrier && (
              <SABSearchItemOfferer
                organizationName={quote.seller_organization.name}
                organizationRoleCode={quote.seller_organization.role_code}
              />
            )}
        </Box>
        <SABSearchItemActions queryString={queryString} isBooking={isBooking} />
      </Stack>
      {!hasNoServices && (
        <>
          <Box mt={TOP_PADDING} data-testid="search-and-book-air-quote-details">
            {services.map((service, index) => (
              <SABSearchItemService
                key={index}
                service={service}
                currency={currency}
              />
            ))}
            <Stack
              mt={2}
              spacing={2}
              display="flex"
              direction="row"
              justifyContent="space-between"
            >
              <Typography children="TOTAL" variant="body1Strong" />
              <Typography
                variant="body1Strong"
                children={getTotalItemPrice(quote, 'EUR')} //Set the total price as EUR by default
              />
            </Stack>
          </Box>
        </>
      )}
    </Grid>
  )
}

export default SABSearchItemInfo
