import { memo } from 'react'
import BookingPartiesContent from './Content'
import { getAddressInfo, getContactInfo } from './BookingParties.utils'
import { BookingPartyForm } from './BookingParties.props'

const BookingParties = ({
  open,
  onClose,
  type,
  booking,
  shipmentId,
}: {
  type: string
  open: boolean
  booking: IBooking
  onClose: () => void
  shipmentId: number
}) => {
  const bookingPartyInfo = booking?.[type] ?? null

  if (!bookingPartyInfo) {
    return null
  }

  const {
    shipment_address: shipmentAddress,
    shipment_contacts: shipmentContact,
  } = bookingPartyInfo ?? null

  const defaultValues: BookingPartyForm = {
    shipmentAddress: getAddressInfo(shipmentAddress),
    shipmentContact: getContactInfo(shipmentContact),
  }

  return (
    <BookingPartiesContent
      open={open}
      type={type}
      onClose={onClose}
      bookingId={booking.id}
      shipmentId={shipmentId}
      defaultValues={defaultValues}
    />
  )
}

export default memo(BookingParties)
