import {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { includes, find, forEach, each } from 'lodash'
import { promisifyAction } from 'src/utils'
import Modal from 'src/components/Common/Modal'
import Autocomplete from 'src/stories/SearchBar'
import { TextArea } from 'src/stories/TextArea'
import { useTranslation } from 'react-i18next'
import { ModalityEnum } from 'src/config/constants'
import {
  shipmentOverviewGetData,
  addressesAddAddress,
  addressesPutAddress,
  assignAddressToContainer,
  assignReferenceToContainer,
} from 'src/stores/actionCreators'
import { Button, Box, InputLabel, TextField } from '@mui/material'
import addressValidators from './addressValidators'
import { IFieldValidator } from './interfaces'
import './styles.scss'

interface IValidationErrors {
  [x: string]: string | null | undefined
}

interface IAddressDetails {
  name: string
  address: string
  postal_code: string
  comment: string
  city: string
  country_id: number | null
  address_id: number | null
  preferred_modalities: ModalityEnum[]
  address_opening_hours: any[]
}

const constructAddress = (address): IAddressDetails => ({
  name: address.name,
  address: address.address,
  postal_code: address.postal_code,
  city: address.city,
  country_id: address.country.id,
  address_id: address.id,
  comment: address.comment || '',
  preferred_modalities: address.preferred_modalities,
  address_opening_hours: address.address_opening_hours || [],
})

const initialAddressDetails: IAddressDetails = {
  name: '',
  address: '',
  postal_code: '',
  comment: '',
  city: '',
  country_id: 0,
  address_id: null,
  preferred_modalities: [],
  address_opening_hours: [],
}

interface IProps {
  open: boolean
  close: () => void
  afterSave: () => void
  containerId?: number
  containerNumber?: string
  address: IInlandTransportAddress | null
  serviceType: 'pickup' | 'delivery'
  containerData: any[]
}

const AddressWindow: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()

  const companyNameUniqValidationMessage: string = t(
    'shipment_containers.validations.company_not_unique',
    'Company already exists'
  )

  const [addressDetails, setAddressDetails] = useState<IAddressDetails>(
    initialAddressDetails
  )
  const [reference, setReference] = useState<string | null>(null)
  const [isAddressUsed, setIsAddressUsed] = useState<boolean>(false)
  const [addressDetailsErrors, setAddressDetailsErrors] = useState<
    IValidationErrors
  >({})
  const dispatch = useDispatch()
  const store = useStore()

  const createAddressAsync = promisifyAction(dispatch, addressesAddAddress)
  const fetchOverview = promisifyAction(dispatch, shipmentOverviewGetData)
  const updateAddressAsync = promisifyAction(dispatch, addressesPutAddress)
  const assignAddressToContainerAsync = promisifyAction(
    dispatch,
    assignAddressToContainer
  )
  const assignReferenceToContainerAsync = promisifyAction(
    dispatch,
    assignReferenceToContainer
  )

  const { countries, addresses } = useSelector((globalState: IGlobalState) => ({
    countries: globalState.countries.list,
    addresses: globalState.addresses.list,
  }))

  const isCompanyNameUniq = useMemo((): boolean => {
    const companyNames: string[] = addresses.map((x) => {
      return addressDetails.address_id === x.id ? '' : x.name
    })
    return !includes(companyNames, addressDetails.name)
  }, [addresses, addressDetails.name])

  useEffect(() => {
    setAddressDetailsErrors({
      ...addressDetailsErrors,
      name: isCompanyNameUniq ? null : companyNameUniqValidationMessage,
    })
  }, [isCompanyNameUniq])

  useEffect(() => {
    if (props.address?.address_id) {
      setReference(props.address.reference)
      const currentAddress = find(addresses, {
        id: props.address.address_id,
      })
      setAddressDetails(
        currentAddress
          ? constructAddress(currentAddress)
          : initialAddressDetails
      )
      if (currentAddress) {
        setIsAddressUsed(true)
      }
    }
  }, [props.address])

  // unmount component
  useEffect(() => {
    if (!props.open) {
      setAddressDetails(initialAddressDetails)
      setAddressDetailsErrors({})
      setReference(null)
      setIsAddressUsed(false)
    }
  }, [props.open])

  const handleAddressChange = useCallback(
    (fieldName: string, value: string | string[] | number | number[]): void => {
      setReference(null)
      setAddressDetails((prevState) => ({ ...prevState, [fieldName]: value }))
      setAddressDetailsErrors({ ...addressDetailsErrors, [fieldName]: null })
    },
    [addressDetails]
  )
  const handleReferenceChange = useCallback(
    (fieldName: string, value: string): void => {
      setReference(value)
    },
    [addressDetails]
  )

  const handleAddressSelect = (address: IAddress | null): void => {
    if (address?.id) {
      setAddressDetails(constructAddress(address))
    } else {
      setAddressDetails(initialAddressDetails)
    }
    setAddressDetailsErrors({})
    setIsAddressUsed(true)
  }

  const checkValidations = (): boolean => {
    let isValid: boolean = true
    const addressErrorsObject: { [key: string]: string } = {}

    // Presence validations
    each(addressValidators, ({ field, validate }: IFieldValidator): void => {
      const errorMessage: string = validate(addressDetails[field])
      if (errorMessage) {
        addressErrorsObject[field] = errorMessage
        isValid = false
      }
    })

    // Uniqueness validation on company name
    if (!isCompanyNameUniq) {
      addressErrorsObject.name = companyNameUniqValidationMessage
      isValid = false
    }

    setAddressDetailsErrors(addressErrorsObject)

    return isValid
  }

  const saveAddress = async (): Promise<any> => {
    const isValid: boolean = checkValidations()
    if (!isValid) {
      return
    }
    const assignAddressObj = {
      address_id: addressDetails.address_id,
      service_type: props.serviceType,
    }

    const assignReferenceObj = {
      reference,
      service_type: props.serviceType,
    }

    if (addressDetails.address_id) {
      await updateAddressAsync(addressDetails.address_id, addressDetails)
    } else {
      await createAddressAsync(addressDetails)
      const singleAddress = store.getState().addresses.singleAddress
      assignAddressObj.address_id = singleAddress.id
    }

    forEach(
      props.containerData,
      async (container, index: number): Promise<any> => {
        await Promise.all([
          assignAddressToContainerAsync(container.id, assignAddressObj),
          assignReferenceToContainerAsync(container.id, assignReferenceObj),
        ])
        fetchOverview(container.shipment.id)

        // TODO: Vlad should fix it
        if (index === props.containerData.length - 1) {
          await props.afterSave()
        }
      }
    )
  }

  const closeWindow = () => {
    setAddressDetails(initialAddressDetails)
    props.close()
  }

  const contacts = useMemo((): any[] => {
    const list: any[] = addresses.slice() || []
    list.unshift({
      id: null,
      name: t(
        'shipment_containers.addresses.add_company_name',
        'Add new company'
      ),
    })
    return list
  }, [addresses])

  const addressOption = useMemo(
    () =>
      contacts.find((option) => addressDetails.address_id === option.id) ||
      null,
    [addressDetails.address_id]
  )

  const countryOption = useMemo(
    () => countries.find(([_, id]) => addressDetails.country_id === id) || null,
    [addressDetails.country_id]
  )

  const modalTitle =
    props.serviceType === 'pickup'
      ? t(
          'shipment_containers.addresses.pickup_title',
          'Pickup information for container'
        )
      : t(
          'shipment_containers.addresses.delivery_title',
          'Delivery information for container'
        )

  return (
    <Modal.Window open={props.open} onClose={closeWindow}>
      <Modal.Title
        children={`${modalTitle} ${
          props.containerData.length ? props.containerData[0].number || '' : ''
        }`}
        onClose={closeWindow}
      />
      <Modal.Content>
        <div className="address-window">
          <Box width="100%">
            <div className="address-window--select-header">
              {props.serviceType === 'pickup'
                ? t(
                    'shipment_containers.addresses.pickup_address',
                    'Pickup address'
                  )
                : t(
                    'shipment_containers.addresses.delivery_address',
                    'Delivery address'
                  )}
            </div>
            <div className="address-window--reference">
              <div className="address-window--reference--notes">
                {props.serviceType === 'pickup'
                  ? t(
                      'shipment_containers.addresses.enter_pickup_address',
                      'Please enter your pickup address'
                    )
                  : t(
                      'shipment_containers.addresses.enter_delivery_address',
                      'Please enter your delivery address'
                    )}
              </div>
            </div>

            <Box data-testid="company-autocomplete">
              <Autocomplete
                label={modalTitle}
                onChange={(option) => {
                  if (option) handleAddressSelect(option)
                }}
                value={addressOption}
                placeholder={
                  !addressDetails.address_id && isAddressUsed
                    ? t(
                        'shipment_containers.addresses.new_company',
                        'New company'
                      )
                    : t(
                        'shipment_containers.addresses.select_company',
                        'Select company'
                      )
                }
                options={contacts}
              />
            </Box>
            <Box mt={2}>
              <InputLabel htmlFor="name">
                {t(
                  'shipments.bookings.parties_modal.fields.company_name',
                  'Company Name'
                )}
              </InputLabel>
              <TextField
                disabled={!!addressDetails.address_id}
                fullWidth
                id="name"
                value={addressDetails.name}
                onChange={(event) => {
                  handleAddressChange('name', event.target.value)
                }}
              />
              <Box mt={2}>
                <TextArea
                  data-testid="address-textarea"
                  id="address"
                  label={t(
                    'shipments.bookings.parties_modal.fields.address',
                    'Address*'
                  )}
                  onChange={(event) => {
                    handleAddressChange('address', event.target.value)
                  }}
                  value={addressDetails?.address}
                  placeholder={t(
                    'shipments.bookings.parties_modal.fields.address',
                    'Address*'
                  )}
                />
              </Box>
              <Box mt={2}>
                <InputLabel htmlFor="postal_code">
                  {t(
                    'shipments.bookings.parties_modal.fields.postal_code',
                    'Postal code'
                  )}
                </InputLabel>
                <TextField
                  data-testid="postal-code-input"
                  fullWidth
                  id="postal_code"
                  value={addressDetails.postal_code}
                  onChange={(event) => {
                    handleAddressChange('postal_code', event.target.value)
                  }}
                />
              </Box>
              <Box mt={2}>
                <InputLabel htmlFor="city">
                  {t('shipments.bookings.parties_modal.fields.city', 'City*')}
                </InputLabel>
                <TextField
                  data-testid="city-input"
                  fullWidth
                  id="city"
                  value={addressDetails.city}
                  onChange={(event) => {
                    handleAddressChange('city', event.target.value)
                  }}
                />
              </Box>
              <Box mt={2} data-testid="country-autocomplete">
                <Autocomplete
                  label={t(
                    'shipments.bookings.parties_modal.fields.country',
                    'Country*'
                  )}
                  onChange={(option) => {
                    if (option) handleAddressChange('country_id', option.id)
                  }}
                  value={
                    countryOption
                      ? {
                          name: countryOption[0],
                          id: countryOption[1],
                        }
                      : null
                  }
                  placeholder={t(
                    'shipments.bookings.parties_modal.fields.country',
                    'Country*'
                  )}
                  options={
                    countries.map(([name, id]) => ({
                      name,
                      id,
                    })) as any
                  }
                />
              </Box>
              <Box mt={2}>
                <TextArea
                  data-testid="comment-textarea"
                  id="comment"
                  label={t(
                    'shipments.bookings.parties_modal.fields.additional_information',
                    'Additional Information'
                  )}
                  onChange={(event) => {
                    handleAddressChange('comment', event.target.value)
                  }}
                  value={addressDetails?.comment}
                />
              </Box>
            </Box>
          </Box>
          <Box ml={2} width="100%">
            <div
              className={`address-window--select-header ${
                !isAddressUsed ? 'disabled' : ''
              }`}
            >
              {props.serviceType === 'pickup'
                ? t(
                    'shipment_containers.addresses.pickup_reference',
                    'Pickup reference'
                  )
                : t(
                    'shipment_containers.addresses.delivery_reference',
                    'Delivery reference'
                  )}
            </div>
            <div className="address-window--reference">
              <div
                className={`address-window--reference--notes ${
                  !isAddressUsed ? 'disabled' : ''
                }`}
              >
                {props.serviceType === 'pickup'
                  ? t(
                      'shipment_containers.addresses.enter_pickup_reference',
                      'If applicable, please enter your pickup reference.'
                    )
                  : t(
                      'shipment_containers.addresses.enter_delivery_reference',
                      'If applicable, please enter your delivery reference.'
                    )}
              </div>

              <Box mt={2}>
                <InputLabel htmlFor="reference">
                  {props.serviceType === 'pickup'
                    ? t(
                        'shipment_containers.addresses.pickup_reference',
                        'Pickup reference'
                      )
                    : t(
                        'shipment_containers.addresses.delivery_reference',
                        'Delivery reference'
                      )}
                </InputLabel>
                <TextField
                  fullWidth
                  disabled={!isAddressUsed}
                  id="reference"
                  data-testid="reference-input"
                  value={reference}
                  onChange={(event) => {
                    handleReferenceChange('reference', event.target.value)
                  }}
                />
              </Box>
            </div>
          </Box>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={closeWindow}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={saveAddress}
          disabled={!isAddressUsed}
          data-testid="save-button"
        >
          {t('common.buttons.save', 'Save')}
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default AddressWindow
