import { GroupedColumns, Column, Group, Table } from './ShowHideDialog.props'

export const getGroupedColumns = (columns: Column[]): GroupedColumns => {
  return columns.reduce<GroupedColumns>((acc, column) => {
    const columnGroups = column.groups

    columnGroups.forEach((group) => {
      if (!acc[group.value]) {
        acc[group.value] = {
          ...group,
          columns: [column],
        }
      } else {
        acc[group.value].columns.push(column)
      }
    })
    return acc
  }, {})
}

export const getTableColumns = (table: Table, query: string) => {
  return table.getAllColumns().reduce<
    {
      label: string
      groups: Group[]
      id: string
      visible: boolean
    }[]
  >((filtered, column) => {
    const tableColumn = table.getColumn(column.id)
    const label = tableColumn?.columnDef.header ?? null
    const searchTerm = query.toLowerCase().trim()

    if (label && label.toLowerCase().includes(searchTerm)) {
      const groups = column.columnDef?.groups ?? []
      filtered.push({
        label,
        groups,
        id: column.id,
        visible: column.getIsVisible(),
      })
    }
    return filtered
  }, [])
}
