import Typography from '@mui/material/Typography'
import { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import DashboardEmptyLine from 'src/components/DashboardOverview/DashboardEmptyLine'
import { useGetUpdatedByOthersShipments } from 'src/services/Api/dashboard'
import { queryCachingOptions } from 'src/services/http-common'
import { history } from 'src/shyppleStore'
import { Table } from 'src/stories'
import { convertDateToDateTime } from '../../../utils/helpers'
import columns from '../common'
import { dashboardLine } from '../DashboardShipmentLine/dashboardLine'

const paginationOptions = {
  page: 0,
  per_page: 10,
  total_count: 10,
}

const DashboardUpdatedByOthers: FunctionComponent<{}> = () => {
  const [pagination, setPagination] = useState(paginationOptions)

  const { onlyWatchedShipments } = useSelector(
    ({ shipmentsWatchedBy }: IGlobalState) => shipmentsWatchedBy
  )

  const setPaginationByResponse = (updatedShipments) => {
    const { page, per_page, total_count } =
      updatedShipments.data || paginationOptions
    setPagination({ page, per_page, total_count })
  }
  const { data, isLoading } = useGetUpdatedByOthersShipments(
    {
      page: pagination.page,
      per_page: pagination.per_page,
      only_watched_shipments: onlyWatchedShipments,
    },
    {
      onSuccess: setPaginationByResponse,
      ...queryCachingOptions.long,
    }
  )
  const { records = [] } = data?.data || {}

  if (!isLoading && !records.length) return <DashboardEmptyLine />

  return (
    <Table
      columns={columns}
      loading={isLoading}
      maxHeight={320}
      onRowClick={(id) => {
        history.push(`/shipments/${id}/conversations`)
      }}
      showHeader={false}
      rows={
        records?.map((shipment: IDashboardShipmentWithComment) => {
          const info = (
            <Typography
              variant="body1"
              children={shipment.chat_comment_message}
            />
          )
          const subtitle = (
            <>
              By:{' '}
              <span style={{ color: shipment.author_organization.color }}>
                {shipment.chat_comment_author} |{' '}
                <b>{shipment.author_organization.name}</b>
              </span>
              <br />
              On: {convertDateToDateTime(shipment.chat_comment_created_at)}
            </>
          )
          return dashboardLine(shipment, subtitle, info)
        }) || []
      }
      pagination={{
        rows: pagination.per_page,
        total: pagination.total_count,
        current: pagination.page,
        onChange: (page) => setPagination({ ...pagination, page }),
      }}
    />
  )
}

export default DashboardUpdatedByOthers
