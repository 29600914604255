import LocationOnIcon from '@mui/icons-material/LocationOn'
import BusinessIcon from '@mui/icons-material/Business'
import {
  modalityIcons,
  ShipmentModalityIcon,
} from 'src/stories/MUI/ShipmentContent/components/ShipmentModalityIcon'
import {
  LoadTypeEnum,
  ModalityEnum,
  modalityOptions,
} from 'src/config/constants'
import { services } from 'src/pages/SearchAndBook/constants'
import {
  FormStateProps,
  FormToggleButtonOptionProps,
  TemplateFormErrorProps,
} from './Form.props'

export const defaultServiceValues = services(ModalityEnum.Sea)
  .map((service) => service.value)
  .reduce((serviceMap, service) => {
    serviceMap[service] = false
    return serviceMap
  }, {})

export const defaultBookingParties = {
  shipper: {
    collaborator: null,
    address: null,
  },
  consignee: {
    collaborator: null,
    address: null,
  },
}

export const defaultCargo = {
  quantity: 0,
  packageTypeId: null,
  description: '',
  length: 0,
  width: 0,
  height: 0,
  totalVolumeCbm: 0,
  weight: 0,
  stackable: null,
}

export const defaultContainerReeferSettings = {
  temperature: '',
  ventilation: '',
  humidity: '',
  ventsOpen: null,
  drainsOpen: null,
  co2Regulation: '',
  comments: '',
}

export const defaultContainer = {
  containerTypeEnum: '',
  amount: 1,
  nonOperatingReefer: false,
  ...defaultContainerReeferSettings,
}

export const initialState: FormStateProps = {
  name: '',
  shipmentRole: '',
  modality: ModalityEnum.Sea,
  loadType: LoadTypeEnum.fcl,
  loadingPort: null,
  dischargePort: null,
  customer: null,
  cifValue: 0,
  incoterm: '',
  services: defaultServiceValues,
  pickupAddress: null,
  deliveryAddress: null,
  bookingParties: { ...defaultBookingParties },
  container: {
    ...defaultContainer,
  },
  cargo: {
    ...defaultCargo,
  },
  hsCode: {
    hsCode: '',
  },
  tags: {
    dangerousGoods: false,
  },
  temperatureSetting: null,
  collaborators: [],
  booking: {
    cargoReadyDate: null,
  },
  references: {
    sharedReference: null,
    internalReference: null,
  },
  sellerOrganization: null,
}

export const bookingPartyKeys = ['shipper', 'consignee']

export const initialFormErrors: TemplateFormErrorProps = {
  bookingParties: false,
  dimensions: false,
  ports: false,
}

export const modalityToggleOptions: FormToggleButtonOptionProps[] = modalityOptions.reduce(
  (acc: FormToggleButtonOptionProps[], option) => {
    if (option.id !== ModalityEnum.Barge && option.id !== ModalityEnum.Road) {
      acc.push({
        ...option,
        icon: <ShipmentModalityIcon modality={option.id} />,
        disabled: option.id === ModalityEnum.Rail,
      })
    }
    return acc
  },
  []
)

export const defaultLoadTypeForModality = {
  [ModalityEnum.Sea]: LoadTypeEnum.fcl,
  [ModalityEnum.Air]: LoadTypeEnum.lcl,
}

export const selectOptionTypeIconMap = {
  ...modalityIcons,
  address: LocationOnIcon,
  collaborator: BusinessIcon,
  none: '',
}

export const ventsAndDrainsOptions = [
  {
    id: 'open',
    value: true,
    label: 'Open',
  },
  {
    id: 'close',
    value: false,
    label: 'Closed',
  },
  {
    id: 'unspecified',
    value: '',
    label: 'Not specified',
  },
]

export const defaultDimensionValidation = {
  width: {
    min: 0,
    max: 240,
  },
  height: {
    min: 0,
    max: 270,
  },
  length: {
    min: 0,
    max: 600,
  },
}
