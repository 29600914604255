import ListItem from '@mui/material/ListItem'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import ListItemButton from '@mui/material/ListItemButton/ListItemButton'

const ShowHideDialogListItem = (props) => {
  const { onClick, selected, label, visible, ...otherProps } = props
  return (
    <ListItem
      disablePadding
      onClick={onClick}
      secondaryAction={
        otherProps.onButtonClick ? (
          <IconButton
            size="small"
            aria-label="toggle-visibility"
            sx={{ color: 'primary.main' }}
            data-testid="toggle-visibility"
            onClick={(e) => {
              if (otherProps.onButtonClick) {
                e.stopPropagation()
                otherProps.onButtonClick()
              }
            }}
          >
            {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        ) : null
      }
    >
      <ListItemButton selected={selected}>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  )
}

export default ShowHideDialogListItem
