import { MRT_ToggleDensePaddingButton as DensityToggleButton } from 'material-react-table'
import Stack from '@mui/material/Stack'
import FullScreenToggleButton from 'src/components/FullScreenToggleButton'
import ShowHideColumnsButton from './ShowHideColumnsButton'

const ToolbarInternalButtons = ({ table }) => {
  const {
    options: {
      enableFullScreenToggle,
      enableHiding,
      enableDensityToggle,
      enableColumnPinning,
      enableColumnOrdering,
    },
  } = table

  return (
    <Stack direction="row" alignItems="center">
      {(enableHiding || enableColumnOrdering || enableColumnPinning) && (
        <ShowHideColumnsButton table={table} />
      )}
      {enableDensityToggle && <DensityToggleButton table={table} />}
      {enableFullScreenToggle && <FullScreenToggleButton />}
    </Stack>
  )
}

export default ToolbarInternalButtons
