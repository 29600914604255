import { styled } from '@mui/material/styles'
import List, { ListProps } from '@mui/material/List'
import { Box, BoxProps } from '@mui/material'

export const StyledList = styled(List)<ListProps>(({ theme }) => ({
  width: '100%',
  paddingTop: 0,
  height: 270,
  paddingBottom: 0,
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderTop: `1px solid ${theme.palette.divider}`,
}))

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}))

export const StyledHeader = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(1.25, 2),
}))
