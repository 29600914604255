import { useState } from 'react'
import omit from 'lodash/omit'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { FormProvider, useForm } from 'react-hook-form'
import {
  addressesPutAddress,
  personalDetailsPutPersonalDetail,
  assignAddress,
  assignContact,
  addressesAddAddress,
  personalDetailsAddPersonalDetail,
  shipmentOverviewGetData,
} from 'src/stores/actionCreators'
import { permissionTo, promisifyAction } from 'src/utils'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'src/stories/Dialogs'
import Divider from '@mui/material/Divider'
import { showNotification } from 'src/stores/actionCreators/notifications'
import BookingPartiesModalContentAddressForm from './Form/AddressForm'
import BookingPartiesModalContentContactForm from './Form/ContactForm'
import { getAddressPayload, getContactPayload } from './BookingParties.utils'
import { BookingPartyForm } from './BookingParties.props'

const BookingPartiesContent = ({
  open,
  onClose,
  type,
  shipmentId,
  bookingId,
  defaultValues,
}: {
  type: string
  open: boolean
  onClose: () => void
  bookingId: number
  shipmentId: number
  defaultValues: BookingPartyForm
}) => {
  const canManageAddressBook = permissionTo('address_book.manage')

  const { t } = useTranslation()
  const [saving, setSaving] = useState(false)

  const methods = useForm<BookingPartyForm>({
    defaultValues,
  })

  const showSuccessSnackbar = (message: string) => {
    dispatch(showNotification({ message, severity: 'success' }))
  }

  const showErrorSnackbar = (message: string) => {
    dispatch(showNotification({ message, severity: 'error' }))
  }

  const dispatch = useDispatch()
  const updateCompany = promisifyAction(dispatch, addressesPutAddress)
  const updateContact = promisifyAction(
    dispatch,
    personalDetailsPutPersonalDetail
  )
  const assignCompanyToBooking = promisifyAction(dispatch, assignAddress)
  const assignContactToBooking = promisifyAction(dispatch, assignContact)
  const saveCompany = promisifyAction(dispatch, addressesAddAddress)
  const saveContact = promisifyAction(
    dispatch,
    personalDetailsAddPersonalDetail
  )

  const fetchOverview = promisifyAction(dispatch, shipmentOverviewGetData)

  const requestContactAssignment = async (
    id: number,
    formData: BookingPartyForm
  ) => {
    await assignContactToBooking(
      {
        contact_id: id,
        booking_party: type,
        comment: formData.shipmentContact?.comment ?? '',
      },
      bookingId
    )
  }

  const requestCompanyAssignment = async (id: number) => {
    await assignCompanyToBooking(
      { address_id: id, booking_party: type },
      bookingId
    )
  }

  const saveDataForExistingContact = async (
    formData: BookingPartyForm,
    contactId: number,
    contactPayload: any
  ) => {
    if (canManageAddressBook) {
      await updateContact(contactId, contactPayload)
    }

    await requestContactAssignment(contactId, formData)
  }

  const saveDataForNewContact = async (
    formData: BookingPartyForm,
    contactPayload: any
  ) => {
    if (!canManageAddressBook) return

    if (contactPayload.name && contactPayload.email && contactPayload.phone) {
      const { id } = await saveContact(contactPayload)
      await requestContactAssignment(id, formData)
    }
  }

  const saveDataForExistingCompany = async (
    companyAddressId: number,
    companyPayload: any,
    contactId: number | null,
    contactPayload: any,
    formData: BookingPartyForm
  ) => {
    if (canManageAddressBook) {
      await updateCompany(companyAddressId, companyPayload)
    }

    await requestCompanyAssignment(companyAddressId)

    if (contactId) {
      await saveDataForExistingContact(formData, contactId, contactPayload)
    } else {
      await saveDataForNewContact(formData, contactPayload)
    }
  }

  const saveDataForNewCompany = async (
    companyPayload: any,
    formData: BookingPartyForm,
    contactPayload: any
  ) => {
    if (!canManageAddressBook) return

    const payload = {
      ...companyPayload,
      name: companyPayload.company_name,
    }
    const { id } = await saveCompany(omit(payload, 'address_id'))
    await requestCompanyAssignment(id)
    await saveDataForNewContact(formData, contactPayload)
  }

  const saveData = async (formData: BookingPartyForm) => {
    const companyAddressId = formData.shipmentAddress?.addressId ?? null
    const contactId = formData.shipmentContact?.id ?? null
    const companyPayload = getAddressPayload(formData.shipmentAddress)
    const contactPayload = getContactPayload(
      formData.shipmentContact,
      formData.shipmentAddress?.addressId ?? null
    )
    setSaving(true)
    try {
      if (companyAddressId) {
        await saveDataForExistingCompany(
          companyAddressId,
          companyPayload,
          contactId,
          contactPayload,
          formData
        )
      } else {
        await saveDataForNewCompany(companyPayload, formData, contactPayload)
      }
      await fetchOverview(shipmentId)
      setSaving(false)
      showSuccessSnackbar(
        t(
          'shipments.bookings.notification.address_book_updated',
          'Address book was updated'
        )
      )
      onClose()
    } catch (error) {
      const unknownError: any = error
      const errorMessage =
        unknownError?.data?.message || 'An unknown error occurred'
      showErrorSnackbar(errorMessage)
      setSaving(false)
    }
  }

  const onSubmit = async (formData: BookingPartyForm) => {
    await saveData(formData)
  }

  const onCloseModal = () => {
    onClose()
  }

  return (
    <Dialog
      keepMounted
      open={open}
      onClose={onCloseModal}
      title={t(
        'shipments.bookings.parties_modal.title',
        'Add {{type}} information',
        {
          type: t(
            `shipments.bookings.parties.${type}`,
            type
          )?.toLocaleLowerCase(),
        }
      )}
      maxWidth="lg"
      PaperProps={{
        // @ts-ignore
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLDivElement>) => {
          event.preventDefault()
          methods.handleSubmit(async (formData: BookingPartyForm) => {
            await onSubmit(formData)
          })(event)
        },
      }}
      actions={
        <>
          <Button
            size="large"
            variant="text"
            onClick={onClose}
            children={t('common.buttons.cancel', 'Cancel')}
          />
          <LoadingButton
            size="large"
            type="submit"
            loading={saving}
            variant="contained"
            children={t('common.buttons.save', 'Save')}
            disabled={!permissionTo('shipments.bookings.parties.manage')}
          />
        </>
      }
    >
      <DialogContent
        children={
          <FormProvider {...methods}>
            <BookingPartiesModalContentAddressForm />
            <Divider sx={{ mt: 3, mb: 2 }} />
            <BookingPartiesModalContentContactForm />
          </FormProvider>
        }
      />
    </Dialog>
  )
}

export default BookingPartiesContent
