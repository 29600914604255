import { pick } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Filters } from 'src/components/Filters'
import { useGetPickupsDeliveriesFilterOptions } from 'src/services/Api/pickupsDeliveries'
import { queryKeys } from 'src/services/endpoints'
import {
  pickupsAndDeliveriesGetData,
  pickupsAndDeliveriesSetFilterOptions,
  pickupsAndDeliveriesUpdateFilters,
} from 'src/stores/actionCreators'
import { queryCachingOptions } from 'src/services/http-common'
import { useEffect, useRef } from 'react'
import { defaultFilters } from 'src/stores/reducers/pickupsAndDeliveries'
import { parse } from 'query-string'
import { statusOptions } from '../constants'

const PickupsAndDeliveriesFilters = ({ filtersOpen }) => {
  const { t } = useTranslation()
  const url = useRef(window.location.href).current
  const dispatch = useDispatch()

  const { filters } = useSelector(
    (state: IGlobalState) => ({ filters: state.pickUpsAndDeliveries.filters }),
    shallowEqual
  )

  const updateFilters = (newFilters: object) => {
    dispatch(pickupsAndDeliveriesGetData(newFilters))
    dispatch(pickupsAndDeliveriesUpdateFilters(newFilters))
  }

  const onSearchChange = (value, isInitialLoad) => {
    isInitialLoad
      ? updateFilters({ ...value })
      : updateFilters({ ...value, page: 1 })
  }

  const yesNoOptions = [
    { label: 'Yes', id: 'true' },
    { label: 'No', id: 'false' },
  ]

  const defaultKeys = ['filter', 'my_shipments', ...Object.keys(defaultFilters)]
  const {
    data: filterOptions,
    isLoading,
  } = useGetPickupsDeliveriesFilterOptions({
    onSuccess: (data) => {
      dispatch(pickupsAndDeliveriesSetFilterOptions(data))
    },
    ...queryCachingOptions.long,
  })

  useEffect(() => {
    const defaultFiltersValues = pick(parse(url), defaultKeys) || {}
    dispatch(
      pickupsAndDeliveriesUpdateFilters({
        ...filters,
        ...defaultFiltersValues,
      })
    )
  }, [])

  return (
    <Filters
      miniFilterLabels={{
        visibility_only: 'OneView shipments',
        organization_ids: 'Organizations',
        cluster_ids: 'Clusters',
        pol_ids: 'POL',
        pod_ids: 'POD',
        departure_date_start: 'ETD Start',
        departure_date_end: 'ETD End',
        arrival_date_start: 'ETA Start',
        arrival_date_end: 'ETA End',
        statuses: 'Transport status',
        transport_ids: 'Transporters',
        service_requested: 'Transport requested',
      }}
      page="inland_transports"
      open={filtersOpen}
      onFiltersUpdate={onSearchChange}
      defaultFiltersKeys={defaultKeys}
      isLoading={isLoading}
      filterOptions={filterOptions}
      filterOptionsQueryKey={queryKeys.pickupsDeliveriesFilterOptions}
      initialFilters={pick(
        filters,
        defaultKeys.filter((f) => f !== 'filter')
      )}
    >
      <Filters.TextInput
        name="search"
        label={t('shipments.filters.search', 'Search this page')}
        placeholder="Search"
        columnSize={4}
        data-testid="transport-search"
      />
      <Filters.FormDatePicker
        name={{
          startDateId: 'departure_date_start',
          endDateId: 'departure_date_end',
        }}
        label={t('shipments.filters.etd', 'Departure date range (ETD)')}
        placeholder="DD-MM"
        inputFormat="dd-MM"
        data-testid="transport-departure-date"
      />
      <Filters.FormDatePicker
        name={{
          startDateId: 'arrival_date_start',
          endDateId: 'arrival_date_end',
        }}
        label={t('shipments.filters.eta', 'Arrival date range (ETA)')}
        placeholder="DD-MM"
        inputFormat="dd-MM"
        data-testid="transport-arrival-date"
      />
      <Filters.FormDatePicker
        name={{
          startDateId: 'delivery_date_start',
          endDateId: 'delivery_date_end',
        }}
        label={t(
          'shipments.filters.delivery_date_range',
          'Delivery date range'
        )}
        placeholder="DD-MM"
        inputFormat="dd-MM"
        data-testid="transport-delivery-date"
      />
      <Filters.MultipleAutocomplete
        name="organization_ids"
        label={t('shipments.filters.organizations', 'Organizations')}
        filterOptionKey="organizations"
        data-testid="transport-Organizations"
      />
      <Filters.MultipleAutocomplete
        name="pol_ids"
        label={t('shipments.filters.pols', 'Port of loading (POL)')}
        filterOptionKey="pols"
        data-testid="transport-pols"
      />
      <Filters.MultipleAutocomplete
        name="pod_ids"
        label={t('shipments.filters.pods', 'Port of discharge (POD)')}
        filterOptionKey="pods"
        data-testid="transport-pods"
      />
      <Filters.MultipleSelect
        name="modalities"
        label={t('shipments.filters.modalities', 'Modality')}
        filterOptionKey="modalities"
        data-testid="transport-modalities"
      />
      <Filters.MultipleSelect
        name="load_types"
        label={t('shipments.filters.load_types', 'Load Type')}
        filterOptionKey="load_types"
        data-testid="transport-load-types"
      />
      <Filters.MultipleSelect
        name="statuses"
        label={t('shipments.filters.transport_status', 'Transport status')}
        options={statusOptions}
        data-testid="transport-transport-status"
      />
      <Filters.MultipleSelect
        name="visibility_only"
        label={t('shipments.filters.visibility_only', 'OneView shipments')}
        options={yesNoOptions}
        permissions={[
          'pickups_deliveries.shypple_admin',
          'pickups_deliveries.limited_manage',
        ]}
        data-testid="transport-visibility-only"
      />
      <Filters.MultipleSelect
        name="service_requested"
        label={t(
          'shipments.filters.transport_requested',
          'Transport requested'
        )}
        options={yesNoOptions}
        data-testid="transport-requested"
      />
      <Filters.MultipleSelect
        name="show_delivered"
        label={t('shipments.filters.delivered', 'Show delivered shipments')}
        options={yesNoOptions}
        data-testid="transport-show-delivered"
      />
      <Filters.MultipleAutocomplete
        name="service_departments"
        label={t('shipments.filters.service_department', 'Service Departments')}
        filterOptionKey="service_departments"
        permissions={['pickups_deliveries.shypple_admin']}
        data-testid="transport-service-departments"
      />
      <Filters.MultipleAutocomplete
        name="cluster_ids"
        label={t('shipments.filters.clusters', 'Clusters')}
        filterOptionKey="clusters"
        permissions={['pickups_deliveries.shypple_admin']}
        data-testid="transport-cluster"
      />
      <Filters.MultipleAutocomplete
        name="transporter_ids"
        label={t('shipments.filters.transports', 'Transporters')}
        filterOptionKey="transporters"
        permissions={['pickups_deliveries.shypple_admin']}
        data-testid="transport-transporter"
      />
      <Filters.MultipleAutocomplete
        name="shipment_statuses"
        label={t('shipments.filters.statuses', 'Shipment status')}
        filterOptionKey="shipment_statuses"
        data-testid="transport-shipment-status"
      />
    </Filters>
  )
}

export default PickupsAndDeliveriesFilters
