import i18next from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import { isArray } from 'lodash'

/* 
`LocalStorageBackend` tries to load translations from browser local storage
 First `HttpBackend` tries to load translations from our own bucket
 Second `HttpBackend` acts as fallback for the remote bucket using Weblate
*/

const formatDateTime = (
  value: string,
  lng: string,
  format: DateTimeFormatOptions
) => {
  if (!value) return ''
  return DateTime.fromISO(value, { setZone: true })
    .setLocale(lng === 'en' ? 'en-GB' : lng)
    .toLocaleString(format)
}

const getFormattedDateRange = (
  value: string,
  lng: string,
  format: DateTimeFormatOptions
) => {
  if (!value || !isArray(value)) {
    return ''
  }
  return value
    .reduce((result, val) => {
      if (val) {
        result.push(formatDateTime(val, lng, format))
      }
      return result
    }, [])
    .join(' - ')
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    debug: true,
    fallbackLng: 'en',
    load: 'languageOnly',
    returnEmptyString: false,
    react: {
      useSuspense: true,
    },
    detection: {
      order: ['querystring', 'localStorage'],
      caches: ['localStorage'],
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: 1 * 60 * 60 * 1000, // 1 hour for local storage
        },
        {
          loadPath: 'https://translations.shypple.com/{{lng}}.json',
          requestOptions: {
            cache: 'no-store',
          },
        },
      ],
    },
  })

i18next.services.formatter?.add('lowercase', (value) => {
  return value.toLowerCase()
})

i18next.services.formatter?.add('DATE_MED', (value, lng = 'en') => {
  return formatDateTime(value, lng, DateTime.DATE_MED)
})

i18next.services.formatter?.add('DATETIME_MED', (value, lng = 'en') => {
  return formatDateTime(value, lng, DateTime.DATETIME_MED)
})

i18next.services.formatter?.add('DATE_RANGE_MED', (value, lng = 'en') => {
  return getFormattedDateRange(value, lng, DateTime.DATE_MED)
})

i18next.services.formatter?.add('DATETIME_RANGE_MED', (value, lng = 'en') => {
  return getFormattedDateRange(value, lng, DateTime.DATETIME_MED)
})

i18next.services.formatter?.add('DATE_RELATIVE', (value, lng = 'en') => {
  if (!value) return ''
  return DateTime.fromISO(value, { setZone: true }).toRelative({
    locale: lng,
  }) as string
})

export default i18next
