import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useGetContactsAsync } from 'src/services/Api/common'
import { getContactInfo } from '../../BookingParties.utils'
import { defaultValues } from '../../BookingParties.constants'
import AutocompleteBase from './AutocompleteBase'

const BookingPartiesModalFormContactAutocomplete = ({ freeSolo }) => {
  const { t } = useTranslation()
  const { getValues, reset } = useFormContext()
  const addressId = getValues('shipmentAddress.addressId')

  const { ownerOrganizationId } = useSelector((state: IGlobalState) => ({
    ownerOrganizationId: state.shipmentOverview.owner_organization_id,
  }))

  const { fetchAsync: getData } = useGetContactsAsync()

  const [options, setOptions] = useState<IPersonalDetail[]>([])
  const [open, setOpen] = useState(false)

  const onInputChange = async (search: string) => {
    const data = await getData({
      search,
      page_size: 50,
      organization_id: ownerOrganizationId,
    })

    const newOptions =
      data?.filter((item) => item.company_address_id === addressId) ?? []

    setOptions(newOptions)
  }

  const onChange = (newValue, oldValue: string) => {
    const shipmentAddress = getValues('shipmentAddress')

    if (typeof newValue === 'string' && oldValue !== newValue) {
      reset({
        shipmentAddress,
        shipmentContact: {
          ...defaultValues.shipmentContact,
          name: newValue,
        },
      })
    } else if (newValue && typeof newValue === 'object') {
      if (newValue?.inputValue) {
        reset({
          shipmentAddress,
          shipmentContact: {
            ...defaultValues.shipmentContact,
            name: newValue.inputValue,
          },
        })
      } else {
        const shipmentContact = { ...getContactInfo(newValue), id: newValue.id }
        reset({
          shipmentAddress,
          shipmentContact,
        })
      }
    } else if (newValue === null) {
      reset({
        shipmentAddress,
        shipmentContact: { ...defaultValues.shipmentContact },
      })
    }
  }

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <AutocompleteBase
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      label={t('shipment_assign_booking_parties.contact_name', 'Contact name')}
      required={false}
      freeSolo={freeSolo}
      options={options}
      onChange={onChange}
      onInputChange={onInputChange}
      name="shipmentContact.name"
      testId="booking-parties-contact-autocomplete"
    />
  )
}

export default BookingPartiesModalFormContactAutocomplete
