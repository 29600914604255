import { UseQueryOptions } from '@tanstack/react-query'
import { ResponseSchema } from 'src/@types/endpoints/response-schema'
import { AxiosError } from 'axios'
import endpoints, { queryKeys } from '../endpoints'
import { apiClient } from '../http-common'
import { useQueryAsync } from '../hooks'

type HubSpotTokenResponse = ResponseSchema<{
  token: string
}>

export const useGetHubSpotTokenAsync = (
  options?: UseQueryOptions<
    HubSpotTokenResponse,
    AxiosError,
    HubSpotTokenResponse
  >
) => {
  const { identification: key } = queryKeys

  const getToken = () => {
    const url = endpoints.identification
    return apiClient.get<any, HubSpotTokenResponse>(url)
  }

  return useQueryAsync([key], getToken, options)
}
