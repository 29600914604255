import { useMemo } from 'react'
import ShowHideDialogListItem from './ShowHideDialogListItem'

const ShowHideDialogListGroups = ({
  label,
  group,
  onClick,
  columns,
  selected,
}) => {
  const visible = useMemo(() => {
    return columns.some((column) => column.visible)
  }, [columns])
  return (
    <ShowHideDialogListItem
      label={label}
      selected={selected}
      onClick={() => onClick(group)}
      visible={visible}
    />
  )
}

export default ShowHideDialogListGroups
