import { NavLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Pill from 'src/stories/MUI/Pill'
import { EmptyStateProps } from './emptyStateProps'

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { button, badgeColor, px = 4 } = props
  const DynamicIcon = props.badgeIcon
  return (
    <Box px={px} pt={4} pb={8} sx={{ width: 'auto' }} data-testid="empty-state">
      <Pill
        color={badgeColor}
        children={<DynamicIcon sx={{ fontSize: 48 }} />}
      />
      <Typography my={3} variant="h3" children={props.title} />
      <Typography
        mb={6}
        maxWidth={410}
        children={props.description}
        sx={{ whiteSpace: 'pre-line' }}
      />
      {button && (
        <Button
          size="large"
          component={NavLink}
          to={button.link}
          variant="outlined"
          activeClassName="active"
          sx={{ '&.active': { display: 'none' } }}
        >
          {button.text}
        </Button>
      )}
    </Box>
  )
}
