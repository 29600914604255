import isEmpty from 'lodash/isEmpty'
import { ISavedFilter } from 'src/@types/endpoints/other'
import {
  savedViewLocalStorageKey,
  tableStorageKey,
} from 'src/components/Overview/constants'
import {
  getStorageKeys,
  setLocalStorage,
} from 'src/components/Common/Table/DataTable/TableWrapper.utils'
import { savedFilterKey } from 'src/components/Overview/Search/constants'

export const setLocalStorageFromSavedView = (
  savedView: ISavedFilter | undefined
) => {
  if (!savedView) {
    return
  }
  const storageKeys = getStorageKeys(tableStorageKey)
  const { filter, table } = savedView
  if (table && !isEmpty(table)) {
    setLocalStorage(
      storageKeys['columnVisibility'],
      table?.columnVisibility ?? {}
    )
    setLocalStorage(storageKeys['columnPinning'], table?.columnPinning ?? {})
    setLocalStorage(storageKeys['columnSizing'], table?.columnSizing ?? {})
    setLocalStorage(storageKeys['columnOrder'], table?.columnOrder ?? {})
    setLocalStorage(storageKeys['columnSorting'], table?.columnSorting ?? [])
    setLocalStorage(storageKeys['density'], table?.density ?? 'compact')
  }

  if (filter && !isEmpty(filter)) {
    setLocalStorage(savedFilterKey, filter)
  }

  setLocalStorage(savedViewLocalStorageKey, savedView.id)
}
