import { BookingPartyForm } from './BookingParties.props'

export const defaultValues: BookingPartyForm = {
  shipmentAddress: {
    companyName: '',
    addressId: null,
    address: '',
    postalCode: '',
    city: '',
    comment: '',
    vatNumber: '',
    eoriNumber: '',
    country: null,
    connectionId: null,
  },
  shipmentContact: {
    id: null,
    name: '',
    email: '',
    phone: '',
    comment: '',
  },
}
