import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import CheckIcon from '@mui/icons-material/Check'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'

const OverviewViewsList = ({
  views,
  savedView,
  onItemClick,
  onSaveClick,
  onRenameClick,
  onDeleteClick,
  savedViewId,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: 240,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 140px)',
          '& ul': { padding: 0 },
          '& ul li': { pl: 2, pr: 1.5 },
        }}
        subheader={<li />}
      >
        <li>
          <ul>
            <ListSubheader sx={{ my: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1Strong">
                  {t('vessels_page.views.my_views', 'Views')}
                </Typography>
              </Stack>
            </ListSubheader>
            {views.map((view) => (
              <ListItemButton
                key={`item-${view.id}`}
                onClick={() => onItemClick(view)}
              >
                {savedViewId === view.id && (
                  <ListItemIcon sx={{ color: 'primary.main', minWidth: 36 }}>
                    <CheckIcon />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={<Typography noWrap children={view.name} />}
                />
              </ListItemButton>
            ))}
          </ul>
        </li>
        {savedView && (
          <li>
            <ul>
              <ListSubheader>
                <Typography variant="body1Strong">
                  {t('vessels_page.views.manage_views', 'Manage views')}
                </Typography>
              </ListSubheader>
              <ListItemButton onClick={onSaveClick}>
                <ListItemText
                  primary={t('vessels_page.views.add_view', 'Add new view')}
                />
              </ListItemButton>
              <ListItemButton onClick={onRenameClick}>
                <ListItemText
                  primary={t(
                    'overview_views.actions.edit',
                    'Rename current view'
                  )}
                />
              </ListItemButton>
              <ListItemButton
                onClick={onDeleteClick}
                disabled={views.length < 2}
              >
                <ListItemText
                  primary={t(
                    'overview_views.actions.delete',
                    'Delete current view'
                  )}
                />
              </ListItemButton>
            </ul>
          </li>
        )}
      </List>
    </>
  )
}

export default OverviewViewsList
