import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'

const BookingPartiesModalFormInput = ({
  label,
  name,
  required = true,
  placeholder = '',
  rules = {},
  disabled = false,
  testId = '',
}) => {
  const { control } = useFormContext()
  return (
    <Box>
      <InputLabel required={required} children={label} />
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? 'This field is required' : false,
          ...rules,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            size="small"
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            disabled={disabled}
            inputProps={{ 'data-testid': testId }}
          />
        )}
      />
    </Box>
  )
}

export default BookingPartiesModalFormInput
