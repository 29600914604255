import RvHookupIcon from '@mui/icons-material/RvHookup'
import { Box, Button } from '@mui/material'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useGetPickupDelivery } from 'src/services/Api/dashboard'
import { queryCachingOptions } from 'src/services/http-common'
import { EmptyState } from 'src/stories/MUI/EmptyState'
import { Table } from 'src/stories/MUI/Table'
import { permissionTo } from 'src/utils'
import { columns } from './constants'
import './styles.scss'

const DashboardPickupAndDelivery = () => {
  const [page, setPage] = useState(1)
  const per_page = 10

  const { settings, onlyWatchedShipments } = useSelector(
    ({ user, shipmentsWatchedBy }: IGlobalState) => ({
      settings: user.user_dashboard_settings,
      onlyWatchedShipments: shipmentsWatchedBy.onlyWatchedShipments,
    })
  )
  const compact = settings.find(({ code }) => code === 'compact_table_density')
  const { data: pageData, isLoading: loading } = useGetPickupDelivery(
    {
      page,
      per_page,
      only_watched_shipments: onlyWatchedShipments,
    },
    queryCachingOptions.long
  )

  const onPageChange = (page: number) => setPage(page + 1)

  return (
    <Box
      sx={{ contentVisibility: 'auto' }}
      data-testid="pickup-and-delivery-component"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography mt={3} mb={2} variant="h2" children="Pick-up & delivery" />
        {permissionTo([
          'pickups_deliveries.limited_manage',
          'pickups_deliveries.full_manage',
          'pickups_deliveries.shypple_admin',
        ]) && (
          <Button
            title="Go to transports"
            to="/transports"
            component={Link}
            variant="outlined"
            size="large"
          >
            Go to transports
          </Button>
        )}
      </Box>
      <Table
        maxHeight={584}
        component={Paper}
        loading={loading}
        columns={columns}
        size={compact?.enabled ? 'small' : 'medium'}
        rows={pageData?.inland_transports || []}
        emptyState={
          <EmptyState
            badgeColor="primary"
            badgeIcon={RvHookupIcon}
            title="Don't start the engine just yet"
            button={{ text: 'Book shipment', link: '/search' }}
            description="There are no pickups and deliveries scheduled over the next week."
          />
        }
        pagination={{
          rows: per_page,
          current: page - 1,
          onChange: onPageChange,
          total: pageData?.total || 0,
        }}
      />
    </Box>
  )
}

export default DashboardPickupAndDelivery
