import { pickBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import Search from 'src/components/Overview/Search'
import { Divider } from '@mui/material'
import Content from 'src/components/Overview/Content'
import { savedFilterKey } from 'src/components/Overview/Search/constants'
import { getLocalStorage } from 'src/components/Common/Table/DataTable/TableWrapper.utils'
import Stack from '@mui/material/Stack'
import { OverviewFilters } from 'src/services/Api/overview/types'
import {
  getLocallyStoredView,
  getDateOptionRangeForWeek,
  getDateOptionRange,
} from 'src/components/Overview/utils'
import { defaultValues, defaultPagination } from './constants'
import './index.scss'

const Overview = () => {
  const { t } = useTranslation()
  const savedFilters = getLocalStorage(savedFilterKey, {})
  const hasSavedArrivalDate = !!savedFilters?.arrival_date
  const savedView = getLocallyStoredView()
  const savedViewFilter = savedView?.filter ?? {}
  const dateSelectionOptions = getDateOptionRangeForWeek(t)
  const defaultETARangeOption = dateSelectionOptions[0]

  const defaultETA =
    !hasSavedArrivalDate && defaultETARangeOption
      ? { arrival_date: getDateOptionRange(defaultETARangeOption) }
      : {}

  const location = useLocation()
  const locationParams = parse(location.search, {
    arrayFormat: 'bracket',
  })

  const urlFilters = pickBy(locationParams, (_value, key) => {
    return defaultPagination.hasOwnProperty(key)
  })

  const methods = useForm<OverviewFilters>({
    defaultValues: {
      ...defaultValues,
      ...urlFilters,
      ...savedFilters,
      ...defaultETA,
      ...savedViewFilter,
    },
  })

  return (
    <FormProvider {...methods}>
      <Stack
        component="main"
        direction="column"
        spacing={0}
        divider={<Divider />}
        sx={{
          height: '100%',
          flexGrow: 1,
          position: 'relative',
        }}
        data-testid="overview-page"
      >
        <Search />
        <Content />
      </Stack>
    </FormProvider>
  )
}

export default Overview
