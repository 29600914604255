import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormContext, FieldValues } from 'react-hook-form'
import { useGetAddressesAsync } from 'src/services/Api/common'
import { IAddressesResponse } from 'src/@types/endpoints/common'
import { getAddressInfo } from '../../BookingParties.utils'
import { defaultValues } from '../../BookingParties.constants'
import AutocompleteBase from './AutocompleteBase'

interface BookingPartiesModalFormAddressAutocompleteProps {
  freeSolo?: boolean
}

const BookingPartiesModalFormAddressAutocomplete = ({
  freeSolo = true,
}: BookingPartiesModalFormAddressAutocompleteProps) => {
  const { t } = useTranslation()
  const { reset } = useFormContext<FieldValues>()
  const params = useParams<{ id: string }>()
  const ownerOrganizationId = useSelector(
    (state: IGlobalState) => state.shipmentOverview.owner_organization_id
  )

  const { fetchAsync: getData } = useGetAddressesAsync()

  const [options, setOptions] = useState<IAddress[]>([])

  const onInputChange = async (search: string) => {
    getData({
      search,
      page_size: 50,
      shipment_id: params?.id ?? null,
      organization_id: ownerOrganizationId,
    }).then((data: IAddressesResponse | undefined) => {
      const newOptions: IAddress[] = data?.data ?? []
      setOptions(newOptions)
    })
  }

  const onChange = (newValue, oldValue: string) => {
    if (typeof newValue === 'string' && oldValue !== newValue) {
      reset({
        ...defaultValues,
        shipmentAddress: {
          ...defaultValues.shipmentAddress,
          companyName: newValue,
        },
      })
    } else if (newValue && typeof newValue === 'object') {
      if (newValue?.inputValue) {
        reset({
          ...defaultValues,
          shipmentAddress: {
            ...defaultValues.shipmentAddress,
            companyName: newValue.inputValue,
          },
        })
      } else {
        const shipmentAddress = getAddressInfo({
          ...newValue,
          address_id: newValue.id,
        })

        reset({
          ...defaultValues,
          shipmentAddress,
        })
      }
    } else if (newValue === null) {
      reset({
        ...defaultValues,
        shipmentAddress: { ...defaultValues.shipmentAddress },
      })
    }
  }

  return (
    <AutocompleteBase
      label={t('shipment_assign_booking_parties.company_name', 'Company name')}
      required={true}
      freeSolo={freeSolo}
      options={options}
      onChange={onChange}
      onInputChange={onInputChange}
      name="shipmentAddress.companyName"
      testId="booking-parties-modal-form-address-autocomplete"
    />
  )
}

export default BookingPartiesModalFormAddressAutocomplete
