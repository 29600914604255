import Grid from '@mui/material/Grid'
import { permissionTo } from 'src/utils'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { patterns } from 'src/config/constants'
import Input from './Elements/Input'
import ContactAutocomplete from './Elements/ContactAutocomplete'

const BookingPartiesModalContentContactForm = () => {
  const { t } = useTranslation()
  const { getValues } = useFormContext()
  const hasContactName =
    getValues('shipmentContact.name') !== null &&
    getValues('shipmentContact.name') !== ''

  const canCreateOrManageContact = permissionTo('address_book.manage')

  return (
    <Grid container rowSpacing={2} columnSpacing={5} maxWidth={670}>
      <Grid item xs={6}>
        <ContactAutocomplete freeSolo={canCreateOrManageContact} />
      </Grid>
      <Grid item xs={6}>
        <Input
          label={t('shipment_assign_booking_parties.email', 'Email')}
          name="shipmentContact.email"
          required={hasContactName}
          rules={{
            pattern: {
              value: patterns.email,
              message: 'Entered value does not match email format',
            },
          }}
          placeholder={t(
            'shipment_assign_booking_parties.email.placholder',
            'Add email'
          )}
          disabled={!canCreateOrManageContact}
          testId="booking-parties-modal-contact-email"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label={t('shipment_assign_booking_parties.phone', 'Phone')}
          name="shipmentContact.phone"
          required={hasContactName}
          placeholder={t(
            'shipment_assign_booking_parties.phone.placeholder',
            'Add phone'
          )}
          disabled={!canCreateOrManageContact}
          testId="booking-parties-modal-contact-phone"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label={t(
            'shipment_assign_booking_parties.contact_comment',
            'More information'
          )}
          name="shipmentContact.comment"
          placeholder={t(
            'shipment_assign_booking_parties.contact_comment.placeholder',
            'Add more information'
          )}
          required={false}
          disabled={!canCreateOrManageContact}
          testId="booking-parties-modal-contact-comment"
        />
      </Grid>
    </Grid>
  )
}

export default BookingPartiesModalContentContactForm
