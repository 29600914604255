import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import Search from '@mui/icons-material/Search'
import { Dialog, DialogContent } from 'src/stories/Dialogs'
import { useMemo, useState, Fragment, useEffect } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, Typography } from '@mui/material'
import ShowHideDialogListItem from './ShowHideDialogListItem'
import ShowHideDialogListGroups from './ShowHideDialogListGroups'
import { getTableColumns, getGroupedColumns } from './ShowHideDialog.utils'
import { StyledList, StyledBox, StyledHeader } from './ShowHideDialog.styles'
import { Table, Column, GroupedColumns } from './ShowHideDialog.props'

interface ShowHideDialogProps {
  open: boolean
  onClose: () => void
  table: Table
}

const ShowHideDialog: React.FC<ShowHideDialogProps> = ({
  open,
  onClose,
  table,
}) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery, setDebouncedQuery] = useState<string>(query)

  const items: Column[] = useMemo(
    () => getTableColumns(table, debouncedQuery),
    [table, debouncedQuery]
  )

  const [allColumns, setAllColumns] = useState<Column[]>(items)

  const groups: GroupedColumns = useMemo(() => getGroupedColumns(allColumns), [
    allColumns,
  ])

  const groupItems = Object.keys(groups)

  const firstGroupWithVisibleColumns = useMemo(
    () => items[0]?.groups[0]?.value ?? groupItems[0],
    [items]
  )

  const [group, setGroup] = useState<string>(firstGroupWithVisibleColumns)

  const columns = groups[group]?.columns ?? []

  const someColumnsVisible = useMemo(
    () => allColumns.some((column) => column.visible),
    [allColumns]
  )

  const toggleColumnVisibility = (columnId: string) => () => {
    setAllColumns((prev) =>
      prev.map((item) =>
        item.id === columnId ? { ...item, visible: !item.visible } : item
      )
    )
  }

  const toggleAllColumnVisibility = () => {
    setAllColumns((prev) =>
      prev.map((item) => ({
        ...item,
        visible: !someColumnsVisible,
      }))
    )
  }

  const handleClose = () => {
    setQuery('')
    onClose()
  }

  const handleSave = () => {
    allColumns.forEach((column) => {
      table.getColumn(column.id)?.toggleVisibility(column.visible)
    })
    handleClose()
  }

  useEffect(() => {
    setAllColumns(items)
    setGroup(firstGroupWithVisibleColumns)
  }, [items])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query)
    }, 300)

    return () => {
      clearTimeout(handler)
    }
  }, [query])

  const getGroupTitle = (groupKey: string) => {
    const groupItem = groups[groupKey]
    const { label } = groupItem
    if (groupItem.i18nKey) {
      return t(groupItem.i18nKey, label)
    }
    return label
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      data-testid="show-hide-dialog"
      aria-labelledby="show-hide-dialog-title"
      title={t('data_table.show_hide_dialog.title', 'Show / hide columns')}
      actions={
        <>
          <Button size="large" onClick={handleClose}>
            {t('common.buttons.cancel', 'Cancel')}
          </Button>
          <Button size="large" variant="contained" onClick={handleSave}>
            {t('common.buttons.save', 'Save')}
          </Button>
        </>
      }
    >
      <DialogContent>
        <Typography mb={3} variant="body1">
          {t(
            'data_table.show_hide_dialog.description',
            'You can hide categories and individual fields to build the view that best suits your purpose.'
          )}
        </Typography>

        <Grid
          container
          width={800}
          spacing={2}
          overflow="hidden"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  sx={{ mb: 1 }}
                  fullWidth
                  autoFocus
                  value={query}
                  variant="standard"
                  placeholder={t(
                    'data_table.show_hide_dialog.search_input.placeholder',
                    'Search columns'
                  )}
                  onChange={(e) => setQuery(e.currentTarget.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment:
                      query.length > 0 ? (
                        <InputAdornment position="end">
                          <IconButton size="small" onClick={() => setQuery('')}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ border: '1px solid grey.400' }}>
            <StyledBox>
              <StyledHeader>
                <Typography variant="body1Strong">
                  {t('data_table.show_hide_dialog.category', 'Category')}
                </Typography>
              </StyledHeader>
              <StyledList>
                <ShowHideDialogListItem
                  label={t(
                    'data_table.show_hide_dialog.show_hide_all',
                    'Show / hide all'
                  )}
                  selected={false}
                  onClick={toggleAllColumnVisibility}
                  visible={someColumnsVisible}
                  onButtonClick={toggleAllColumnVisibility}
                />
                <Divider component="li" />
                {groupItems.map((groupItem: string, index: number) => {
                  return (
                    <Fragment key={groupItem}>
                      <ShowHideDialogListGroups
                        group={groupItem}
                        onClick={setGroup}
                        label={getGroupTitle(groupItem)}
                        selected={group === groupItem}
                        columns={groups[groupItem].columns}
                      />
                      {index < groupItems.length - 1 && (
                        <Divider component="li" />
                      )}
                    </Fragment>
                  )
                })}
              </StyledList>
            </StyledBox>
          </Grid>
          <Grid item xs={6}>
            <StyledBox>
              <StyledHeader>
                <Typography variant="body1Strong">
                  {t('data_table.show_hide_dialog.field', 'Field')}
                </Typography>
              </StyledHeader>
              <StyledList>
                {columns.map((column, index) => (
                  <Fragment key={column.id}>
                    <ShowHideDialogListItem
                      selected={false}
                      onClick={toggleColumnVisibility(column.id)}
                      label={column.label}
                      visible={column.visible}
                      onButtonClick={toggleColumnVisibility(column.id)}
                    />
                    {index < columns.length - 1 && <Divider component="li" />}
                  </Fragment>
                ))}
              </StyledList>
            </StyledBox>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ShowHideDialog
