import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import { ModalityEnum } from 'src/config/constants'
import { DemurrageAndDetentionPrice } from 'src/@types/endpoints/demurrageAndDetention'
import * as actions from '../actions'

declare global {
  type InlandTransportService = 'pickup' | 'delivery'

  type InlandTransportStatus =
    | 'estimated'
    | 'requested'
    | 'proposed'
    | 'confirmed'

  interface IShipmentPickupAndDelivery {
    shipmentContainersData: IShipmentContainer[]
    shipmentMainData: IShipmentMainData
    pickupAndDeliveryDataReceived: boolean
  }

  interface IShipmentShort {
    estimated_arrival_date: string | null
    estimated_departure_date: string | null
    id: number
    our_reference: string | null
    internal_reference: string | null
    reference: string | null
    url: string
    public_shipment_link?: string
    port_pair: IPortPair
  }

  interface IContainerShort {
    id: number
    number: string | null
    url: string
    type: string
    container_index: string
  }

  interface ICarrierShort {
    id: number
    name: string
    scac: string
  }

  interface IShipmentMainData {
    bl_number: string
    booking_number: string
    carrier: ICarrierShort
    closing_datetime: string
    closing_datetime_status: string
    containers: IContainerShort[]
    costs_currency: string
    created_at: string
    customs_documentation_complete: boolean
    delivery_available_from: string | null
    documents: any[]
    duty_tax_costs: string
    estimated_arrival_date: string
    estimated_departure_date: string
    id: number
    load_type: string
    order_lines: any[]
    pickup_available_from: string | null
    pickup_available_to: string | null
    destination_demurrage_starting_from: string | null
    port_pair: any[]
    reference: string | null
    shipment_phase: string
    status_text: string
    title: string
    transportation_costs: string
    type: string
    updated_at: string
    url: string
  }
  interface IShipmentContainerShort {
    id: number
    type: string
    type_name: string
    number: string | null
    url: string
    status: string | null
    container_event_exceptions: string[]
    container_index: string
    discharged_at: string | null
    pod_customs_documents_present: boolean
    pod_customs_inspection_status: string | null
    pod_customs_inspection_type: string | null
  }
  interface IShipmentContainerDemurrageAndDetention {
    service_item: {
      id: number
      name: string
      code: string
    }
    free_range: {
      total: number
      used: number
      calculation_method: string
    }
    costs: DemurrageAndDetentionPrice
  }
  interface IShipmentContainer {
    actual_first_day_of_demurrage?: string
    cargo: ICargo[]
    container_event_exceptions: string[]
    container_events: IContainerEvent[]
    devanning_date: string
    discharged_at: string | null
    hs_codes: IContainerHsCode[]
    id: number
    inland_transports: IInlandTransport[]
    milestones: IMilestone[]
    fresh_inspection_task: IMilestoneTask
    number: string | null
    packages: number | null
    pod_customs_documents_present: boolean
    pod_customs_inspection_status: string | null
    pod_customs_inspection_type: string | null
    seal_number: string | null
    shipment: IShipmentShort
    status: string | null
    total_cargo_weight_kg: string
    total_volume_cbm: string
    container_type: { id: number; code: string; name: string }
    url: string
    temperature: number | null
    humidity: number | null
    ventilation: number | null
    vents_open: boolean
    drains_open: boolean
    co2_regulation: string | null
    comments: string | null
    equipment_controlled_atmosphere: boolean
    delivery_inspection_datetime: string | null
    food_grade: boolean
    heavy_tested: boolean
    non_operating_reefer: boolean
    shippers_owned: boolean
    demurrage_and_detention: IShipmentContainerDemurrageAndDetention[]
  }

  interface IContainerEvent {
    id: number
    created_at: string
    event_type_description: string
    exception: boolean
    template_name: string
    template_variables: IPurchaseOrderTemplateVariables
    user: IAuthor
  }

  interface IShipmentAddress {
    address_id: number
    address_type: string
    city: string
    country: ICountry
    created_at: string
    id: number
    name: string
    postal_code: string
    reference: string
    state: string
    address: string
    comment?: string
    vat_number?: string
    eori_number?: string
  }

  interface IInlandTransport {
    id: number
    service: InlandTransportService
    modality: ModalityEnum
    status: InlandTransportStatus
    pickup_delivery_time: string | null
    closing_time: string | null
    pickup_delivery_time_editable: boolean
    inland_transport_addresses: IInlandTransportAddress[]
  }

  interface IInlandTransportAddress {
    datetime_from: string
    datetime_to: string
    datetime_status: InlandTransportStatus
    id: number
    datetime_manageable: boolean
    reference: string | null
    address_id: number | null
    address_type: string | null
    name: string | null
    address: string | null
    postal_code: string | null
    city: string | null
    state: string | null
    country: ICountry | null
    created_at: string
    pickup_delivery: boolean
  }
}

export const initialShipmentPickupAndDelivery = {
  shipmentContainersData: [],
  shipmentMainData: {
    bl_number: '',
    booking_number: '',
    carrier: {
      id: 0,
      name: '',
      scac: '',
    },
    closing_datetime: '',
    closing_datetime_status: '',
    containers: [],
    costs_currency: '',
    created_at: '',
    customs_documentation_complete: false,
    delivery_available_from: '',
    documents: [],
    duty_tax_costs: '',
    estimated_arrival_date: '',
    estimated_departure_date: '',
    id: 0,
    load_type: '',
    order_lines: [],
    pickup_available_from: '',
    pickup_available_to: '',
    destination_demurrage_starting_from: '',
    port_pair: [],
    reference: '',
    shipment_phase: 'origin',
    status_text: '',
    title: '',
    transportation_costs: '',
    type: '',
    updated_at: '',
    url: '',
  },
  inlandTransportsStatuses: [],
  currentInlandTransportsStatus: null,
  pickupAndDeliveryDataReceived: false,
}

export const clearState: Reducer<IShipmentPickupAndDelivery, AnyAction> = () =>
  initialShipmentPickupAndDelivery

const receiveShipmentContainers: Reducer<
  IShipmentPickupAndDelivery,
  AnyAction
> = (state, { payload }) => ({
  ...state,
  shipmentContainersData: payload,
  pickupAndDeliveryDataReceived: true,
})

const receiveShipmentData: Reducer<IShipmentPickupAndDelivery, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  shipmentMainData: payload,
})

const receiveCurrentInlandTransportsStatus: Reducer<
  IShipmentPickupAndDelivery,
  AnyAction
> = (state, { payload }) => ({
  ...state,
  currentInlandTransportsStatus: payload,
})

const updateShipmentContainer: Reducer<
  IShipmentPickupAndDelivery,
  AnyAction
> = (state, { payload }) => {
  return {
    ...state,
    shipmentContainersData: state.shipmentContainersData.map((shipment) =>
      shipment.id === payload.id ? { ...shipment, ...payload } : shipment
    ),
  }
}

const copyContainerAttributes: Reducer<
  IShipmentPickupAndDelivery,
  AnyAction
> = (state, { payload }) => {
  return {
    ...state,
    shipmentContainersData: payload,
  }
}

export default createReducer(initialShipmentPickupAndDelivery, {
  [actions.CLEAR_PICKUP_AND_DELIVERY_STATE]: clearState,
  [actions.SHIPMENT_CONTAINERS_GET_DATA_SUCCESS]: receiveShipmentContainers,
  [actions.SHIPMENT_GET_MAIN_DATA_SUCCESS]: receiveShipmentData,
  [actions.SHOW_INLAND_TRANSPORT_STATUS_SUCCESS]: receiveCurrentInlandTransportsStatus,
  [actions.CONTAINER_ASSIGN_DATETIME_SUCCESS]: receiveCurrentInlandTransportsStatus,
  [actions.UPDATE_SHIPMENT_CONTAINER]: updateShipmentContainer,
  [actions.COPY_CONTAINER_ATTRIBUTES_SUCCESS]: copyContainerAttributes,
})
