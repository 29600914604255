import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { FunctionComponent, useState } from 'react'
import { queryCachingOptions } from 'src/services/http-common'
import Map from 'src/components/Map'
import { useGetShipmentsTrack } from 'src/services/Api/maps'

import MapPagination from './MapPagination'
import './styles.scss'

const MapOverviews: FunctionComponent = () => {
  const [selectedPage, setSelectedPage] = useState<number>(1)

  const { data } = useGetShipmentsTrack(
    { page: selectedPage - 1 },
    queryCachingOptions.long
  )
  const items = data?.data.map_content || []

  const { t } = useTranslation()

  return (
    <div className="track-and-trace">
      <div className="dashboard-header--top">
        <Typography variant="h2" children={t('shipments_map.page_heading')} />
        <MapPagination
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
      <Map markers={items} />
    </div>
  )
}

export default MapOverviews
